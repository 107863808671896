import Swiper, {Pagination} from "swiper";

/**
 * Dynamic card height
 */

const fixSliderCardHeight = (slider) => {
    const { innerWidth } = window;
    if (innerWidth <= 991) {
        const sliderWidth = slider.width;
        const card = document.querySelector(".about .media .card");
        card.style.height = `${sliderWidth + 130}px`;
    }
};

/**
 * Slider
 */
const swiper = new Swiper(".swiper", {
    modules: [Pagination],
    slidesPerView: 3,
    centeredSlides: true,
    spaceBetween: 100,
    loop: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        992: {
            slidesPerView: 3,
        }
    },
    on: {
        init: function () {
            fixSliderCardHeight(this);
        },
        breakpoint: function () {
            fixSliderCardHeight(this);
        },
    },
});

/**
 * FAQ Accordion
 */
const faqWrapper = document.querySelector(".faq > .items");

if (faqWrapper) {
    const faqItems = document.querySelectorAll(".item > .title");
    faqItems.forEach((faqItem) => {
        faqItem.addEventListener("click", (event) => {
            const parent = faqItem.parentElement;
            const activeFaqItems = document.querySelectorAll(".item.active");
            if (activeFaqItems) {
                activeFaqItems.forEach((activeFaqItem) => {
                    if (parent !== activeFaqItem) {
                        toggleRow(activeFaqItem);
                    }
                });
            }
            toggleRow(parent);
        });
    });

    const toggleRow = (element) => {
        const textElement = element.querySelector(".text");
        element.classList.toggle("active");
        textElement.style.maxHeight = textElement.style.maxHeight ? null : textElement.scrollHeight + "px";
    };
}

/**
 * Parallax
 */
const heroElement = document.querySelector(".parallax-move");
const mediaAvatar = document.querySelector(".drops .avatar > img");

if (heroElement) {
    window.addEventListener('scroll', () => {
        const { scrollY, innerWidth } = window;
        if (innerWidth <= 991) {
            heroElement.style.top = scrollY / 15 + 'px';
        } else {
            heroElement.style.top = 210 + scrollY / 15 + 'px';
        }
    });
}

if (mediaAvatar) {
    window.addEventListener('scroll', () => {
        const { scrollY } = window;
        if (innerWidth > 991) {
            mediaAvatar.style.marginTop = scrollY / 32 + 'px';
        }
    });
}


/**
 * Lazy loading
 */

document.addEventListener("DOMContentLoaded", function() {
    var lazyBackgrounds = [].slice.call(document.querySelectorAll(".lazy-background"));
    if ("IntersectionObserver" in window) {
        let lazyBackgroundObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    entry.target.classList.add("visible");
                    lazyBackgroundObserver.unobserve(entry.target);
                }
            });
        });

        lazyBackgrounds.forEach(function(lazyBackground) {
            lazyBackgroundObserver.observe(lazyBackground);
        });
    }
});

document.addEventListener("DOMContentLoaded", function() {
    var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));

    if ("IntersectionObserver" in window) {
        let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    let lazyImage = entry.target;
                    lazyImage.src = lazyImage.dataset.src;
                    lazyImage.srcset = lazyImage.dataset.srcset;
                    lazyImage.classList.remove("lazy");
                    lazyImageObserver.unobserve(lazyImage);
                }
            });
        });

        lazyImages.forEach(function(lazyImage) {
            lazyImageObserver.observe(lazyImage);
        });
    } else {
        // Possibly fall back to event handlers here
    }
});

/**
 * Get ticker
 */
document.addEventListener("DOMContentLoaded", () => {
    const req = new Request("https://fozeus.me/ticker.json");
    fetch(req)
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return response.json();
        })
        .then((response) => {
            document.querySelectorAll('.ticker-price').forEach((el) => {
                el.textContent = (1000 / response.price).toFixed(3);
            });
        });
});